import React, { useState, useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import './OurTeam.scss';
import bobyImage from '../../Images/bobby_singh.jpg';
import sharathImage from '../../Images/Sharath.JPG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const teamMembers = [
  {
    name: 'Sharath Shankar Shetty',
    position: 'Partner',
    experience: '10+ years in restaurant management',
    expertise: 'Expertise in operational efficiency and business growth',
    linkedin: 'https://www.linkedin.com/in/sharath-shankar-shetty/',
    image: sharathImage
  },
  {
    name: 'Boby Singh',
    position: 'Partner',
    experience: '10+ years of experience as a chef',
    expertise: 'Culinary innovation and kitchen management specialist',
    linkedin: 'https://www.linkedin.com/in/boby-singh/',
    image: bobyImage
  },
  // You can easily add more team members here
];

const OurTeam = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: false, amount: 0.2 });

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const nextMember = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
  };

  const prevMember = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + teamMembers.length) % teamMembers.length);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section ref={sectionRef} className="our-team">
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
      >
        Our Exceptional Leadership Team
      </motion.h2>
      <motion.p
        className="intro"
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        At Narasimha Enterprises Hospitality, our leadership team embodies excellence. 
        With vast experience in restaurant management and culinary innovation, we bring passion and expertise to every aspect of our operations. 
        Meet the individuals shaping our culture and driving our success:
      </motion.p>
      <motion.div 
        className={`team-grid ${isMobile ? 'mobile-view' : ''}`}
        variants={containerVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        {isMobile ? (
          <>
           
            <motion.div
      key={currentIndex}
      className="team-member"
      variants={itemVariants}
    >
              <div className="member-image">
                <img src={teamMembers[currentIndex].image} alt={teamMembers[currentIndex].name} />
              </div>
              <div className="member-info">
                <h3>{teamMembers[currentIndex].name}</h3>
                <p className="position">{teamMembers[currentIndex].position}</p>
                <p className="experience">{teamMembers[currentIndex].experience}</p>
                <p className="expertise">{teamMembers[currentIndex].expertise}</p>
                <a href={teamMembers[currentIndex].linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-link">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </motion.div>
            <div className="nav-buttons">
      <button className="nav-button prev" onClick={prevMember}>
        <FaChevronLeft />
      </button>
      <button className="nav-button next" onClick={nextMember}>
        <FaChevronRight />
      </button>
    </div>
          </>
        ) : (
          teamMembers.map((member, index) => (
            <motion.div key={index} className="team-member" variants={itemVariants}>
              <div className="member-image">
                <img src={member.image} alt={member.name} />
              </div>
              <div className="member-info">
                <h3>{member.name}</h3>
                <p className="position">{member.position}</p>
                <p className="experience">{member.experience}</p>
                <p className="expertise">{member.expertise}</p>
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-link">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
            </motion.div>
          ))
        )}
      </motion.div>
    </section>
  );
};

export default OurTeam;